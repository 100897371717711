import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "performer-bio" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_typography_title, { level: 3 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Bio")), 1)
        ]),
        _: 1
      }),
      (_ctx.performerDetails.performerProfile?.introduction)
        ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
            key: 0,
            innerHTML: _ctx.performerDetails.performerProfile?.introduction,
            class: "performer-bio__paragraph"
          }, null, 8, ["innerHTML"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.t("No biography.")), 1))
    ]),
    _: 1
  }))
}