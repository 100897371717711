import gql from "graphql-tag";

export const shortenedLinkGql = gql`
  query ShortenedLink($input: ShortenedLinkInput!) {
    shortenedLink(input: $input) {
      ... on ShortenedLink {
        fullUrl
        shortUrl
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message
        }
      }
    }
  }
`;
