import gql from "graphql-tag";

export const generateShortenedLinkGql = gql`
  mutation GenerateShortenedLink($input: GenerateShortenedLinkInput!) {
    generateShortenedLink(input: $input) {
      ... on ShortenedLink {
        fullUrl
        shortUrl
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message

          ... on InvalidInputError {
            field
          }
        }
      }
    }
  }
`;
