import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PerformerSummary = _resolveComponent("PerformerSummary")!
  const _component_PerformerHireButton = _resolveComponent("PerformerHireButton")!
  const _component_PerformerShortLink = _resolveComponent("PerformerShortLink")!
  const _component_PerformerBio = _resolveComponent("PerformerBio")!
  const _component_PerformerGallery = _resolveComponent("PerformerGallery")!
  const _component_PerformerVideos = _resolveComponent("PerformerVideos")!
  const _component_LayoutPerformer = _resolveComponent("LayoutPerformer")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, { class: "performer-details-display" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutPerformer, { loading: _ctx.performerDetailsLoading }, {
        side: _withCtx(() => [
          _createVNode(_component_PerformerSummary, { performerDetails: _ctx.performerDetails }, null, 8, ["performerDetails"]),
          _createVNode(_component_PerformerHireButton, { onClick: _ctx.handlePerformerActionClick }, null, 8, ["onClick"]),
          _createVNode(_component_PerformerShortLink, { performerDetails: _ctx.performerDetails }, null, 8, ["performerDetails"]),
          _createVNode(_component_PerformerBio, { performerDetails: _ctx.performerDetails }, null, 8, ["performerDetails"])
        ]),
        main: _withCtx(() => [
          _createVNode(_component_PerformerGallery, { performerDetails: _ctx.performerDetails }, null, 8, ["performerDetails"]),
          _createVNode(_component_PerformerVideos, { performerDetails: _ctx.performerDetails }, null, 8, ["performerDetails"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PerformerHireButton, {
              onClick: _ctx.handlePerformerActionClick,
              class: "performer-details-display__performer-hire-button--main"
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }))
}