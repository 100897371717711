import {
  ShortenedLinkInput,
  GenerateShortenedLinkInput,
} from "@/../__generated__/globalTypes";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import {
  GenerateShortenedLink,
  GenerateShortenedLinkVariables,
  GenerateShortenedLink_generateShortenedLink_ShortenedLink,
} from "@/api/siteConfigs/__generated__/GenerateShortenedLink";
import {
  ShortenedLink,
  ShortenedLinkVariables,
  ShortenedLink_shortenedLink_ShortenedLink,
} from "@/api/siteConfigs/__generated__/ShortenedLink";
import { generateShortenedLinkGql } from "@/api/siteConfigs/generateShortenedLink";
import { shortenedLinkGql } from "@/api/siteConfigs/shortenedLink";
import { apiErrorCodes } from "@/shared/utils/constants";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";

export const useShortenedLink = () => {
  // === Queries ===
  const {
    loading: resolveShortenedLinkLoading,
    refetch: refetchShortenedLink,
  } = useQuery<ShortenedLink, ShortenedLinkVariables>(shortenedLinkGql, () => ({
    input: { url: "" },
  }));

  const resolveShortenedLink = async (input: ShortenedLinkInput) => {
    const response = await refetchShortenedLink({ input });
    const parsedResponse =
      parseGqlResponse<ShortenedLink_shortenedLink_ShortenedLink>(
        "ShortenedLink",
        response,
        apiErrorCodes.INVALID_INPUT
      );

    if (parsedResponse.error || response?.error) {
      return "";
    }

    return parsedResponse.data?.fullUrl ?? "";
  };

  // === Mutations ===
  const {
    mutate: generateShortenedLinkMutate,
    loading: generateShortenedLinkLoading,
  } = useCustomMutation<GenerateShortenedLink, GenerateShortenedLinkVariables>(
    generateShortenedLinkGql
  );

  /** Returns shortened URL */
  const generateShortenedLink = async (input: GenerateShortenedLinkInput) => {
    const response = await generateShortenedLinkMutate({ input });
    const parsedResponse =
      parseGqlResponse<GenerateShortenedLink_generateShortenedLink_ShortenedLink>(
        "ShortenedLink",
        response
      );

    if (parsedResponse.error) {
      throw parsedResponse.error;
    }

    return parsedResponse.data?.shortUrl;
  };

  return {
    resolveShortenedLinkLoading,
    resolveShortenedLink,

    generateShortenedLink,
    generateShortenedLinkLoading,
  };
};
