
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { usePerformerDetails } from "@/shared/composables/usePerformerDetails";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import LayoutPerformer from "@/shared/components/Layouts/LayoutPerformer.vue";
import PerformerSummary from "@/shared/components/Performers/PerformerSummary.vue";
import PerformerHireButton from "@/shared/components/Performers/PerformerHireButton.vue";
import PerformerShortLink from "@/shared/components/Performers/PerformerShortLink.vue";
import PerformerBio from "@/shared/components/Performers/PerformerBio.vue";
import PerformerGallery from "@/shared/components/Performers/PerformerGallery.vue";
import PerformerVideos from "@/shared/components/Performers/PerformerVideos.vue";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";

export default defineComponent({
  setup() {
    const { t, n } = useI18n();
    const route = useRoute();
    const { redirectToHomepage } = useRedirectHelper();

    const id = String(route.params.id);

    const { performerDetails, performerDetailsLoading } = usePerformerDetails({
      input: { id },
    });

    const handlePerformerActionClick = () => {
      if (performerDetails.value?.id) {
        redirectToHomepage();
      }
    };

    return {
      t,
      n,
      performerDetails,
      performerDetailsLoading,
      handlePerformerActionClick,
    };
  },
  components: {
    PageLayout,
    LayoutPerformer,
    PerformerSummary,
    PerformerHireButton,
    PerformerShortLink,
    PerformerBio,
    PerformerGallery,
    PerformerVideos,
  },
});
