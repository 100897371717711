
import { defineComponent, PropType } from "vue";
import { fallbackSrc } from "@/shared/utils/constants";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import AvatarIcon from "@/shared/components/Icons/AvatarIcon.vue";

/** Theme of avatar in `<border>-<background>` colors */
export type AvatarTheme = "white-gray" | "gray-white" | "white-blue";

export default defineComponent({
  props: {
    /** src of the image. Default is "#" to trigger antdv's image fallback handling */
    avatarSrc: String,
    altText: String,
    /** Different color themes */
    theme: {
      type: String as PropType<AvatarTheme>,
      default: "white-gray",
    },
    showDeleteButton: Boolean,
    clickable: Boolean,
    noBorder: Boolean,
    small: Boolean,
  },
  emits: ["on-avatar-click", "on-delete"],
  setup() {
    return { fallbackSrc };
  },
  components: {
    AppIcon,
    AvatarIcon,
  },
});
