import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, createBlock as _createBlock } from "vue"

const _hoisted_1 = { ref: "performerVideosRef" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "performer-videos__desktop-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "performer-videos__desktop-wrapper__video ig"
}
const _hoisted_5 = {
  key: 1,
  class: "performer-videos__desktop-wrapper__video vm"
}
const _hoisted_6 = {
  key: 2,
  class: "performer-videos__desktop-wrapper__video tt"
}
const _hoisted_7 = {
  key: 3,
  class: "performer-videos__desktop-wrapper__video if"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_InstagramEmbedded = _resolveComponent("InstagramEmbedded")!
  const _component_VimeoEmbedded = _resolveComponent("VimeoEmbedded")!
  const _component_TikTokEmbedded = _resolveComponent("TikTokEmbedded")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_VideoEmbedCarousel = _resolveComponent("VideoEmbedCarousel")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "performer-videos" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_title, { level: 3 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Videos")), 1)
          ]),
          _: 1
        }),
        (_ctx.embedUrls.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (!_ctx.isMobile)
                ? (_openBlock(), _createBlock(_Suspense, {
                    key: 0,
                    onResolve: _ctx.handleInjectEmbedScripts
                  }, {
                    fallback: _withCtx(() => [
                      _createVNode(_component_a_skeleton, { active: "" })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.embedUrls, (url) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: url }, [
                            (_ctx.isValidInstagramUrl(url))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                  _createVNode(_component_InstagramEmbedded, { url: url }, null, 8, ["url"])
                                ]))
                              : (_ctx.isValidVimeoUrl(url))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createVNode(_component_VimeoEmbedded, { "embed-src": url }, null, 8, ["embed-src"])
                                  ]))
                                : (_ctx.isValidTiktokUrl(url))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                      _createVNode(_component_TikTokEmbedded, { url: url }, null, 8, ["url"])
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                      _createElementVNode("iframe", {
                                        src: url,
                                        frameborder: "0",
                                        allowfullscreen: ""
                                      }, null, 8, _hoisted_8)
                                    ]))
                          ], 64))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }, 8, ["onResolve"]))
                : (_openBlock(), _createBlock(_component_VideoEmbedCarousel, {
                    key: 1,
                    "embed-urls": _ctx.embedUrls,
                    class: "performer-videos__mobile-carousel"
                  }, null, 8, ["embed-urls"]))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.t("No video available.")), 1))
      ], 512)
    ]),
    _: 1
  }))
}