import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "performer-gallery__image-wrapper"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_carousel = _resolveComponent("a-carousel")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "performer-gallery" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_typography_title, { level: 3 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Gallery")), 1)
        ]),
        _: 1
      }),
      (_ctx.images.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.isMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
                    return (_openBlock(), _createBlock(_component_a_image, {
                      key: image?.image,
                      src: image?.thumbnail ?? '#',
                      preview: {
            src: image?.image ?? '#',
          },
                      class: "performer-gallery__image"
                    }, null, 8, ["src", "preview"]))
                  }), 128))
                ]))
              : (_openBlock(), _createBlock(_component_a_carousel, {
                  key: 1,
                  class: _normalizeClass([
          'performer-gallery__carousel',
          'slides-' + _ctx.imagesCarouselSlideToShow,
        ]),
                  "dots-class": "slick-dots",
                  slidesToShow: _ctx.imagesCarouselSlideToShow,
                  centerPadding: "8px"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
                      return (_openBlock(), _createBlock(_component_a_image, {
                        key: image?.image,
                        src: image?.thumbnail ?? '#',
                        preview: {
            src: image?.image ?? '#',
          },
                        class: _normalizeClass([
            'performer-gallery__carousel__image',
            'slides-' + _ctx.imagesCarouselSlideToShow,
          ])
                      }, null, 8, ["src", "preview", "class"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["class", "slidesToShow"]))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t("No image available.")), 1))
    ]),
    _: 1
  }))
}