import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "performer-short-link__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_InputTextCopy = _resolveComponent("InputTextCopy")!
  const _component_Card = _resolveComponent("Card")!

  return (_ctx.generateShortenedLinkLoading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, { key: 0 }))
    : (_ctx.shortLink)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 1,
          class: "performer-short-link"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputTextCopy, {
              text: _ctx.shortLink,
              successMessage: _ctx.t('Copied URL to clipboard')
            }, {
              before: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_AppIcon, {
                    name: "share",
                    class: "h-6 w-6"
                  }),
                  _createVNode(_component_a_typography_text, { class: "font-bold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Share Link")), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["text", "successMessage"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}