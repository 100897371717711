import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-text-copy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "before"),
    _createVNode(_component_InputText, _mergeProps(_ctx.$attrs, { value: _ctx.text }), {
      suffix: _withCtx(() => [
        _createVNode(_component_a_button, {
          class: "input-text-copy__button",
          onClick: _ctx.handleCopy
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Copy")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 16, ["value"])
  ]))
}