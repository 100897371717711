import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "performer-summary__wrapper" }
const _hoisted_2 = { class: "performer-summary__top" }
const _hoisted_3 = {
  key: 0,
  class: "performer-summary__stats"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_PerformerRating = _resolveComponent("PerformerRating")!
  const _component_PerformerBadgeList = _resolveComponent("PerformerBadgeList")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_PerformerIndustries = _resolveComponent("PerformerIndustries")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "performer-summary" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Avatar, {
            "avatar-src": _ctx.performerDetails.performerProfile?.avatar,
            class: "performer-summary__avatar"
          }, null, 8, ["avatar-src"]),
          _createVNode(_component_a_typography_title, {
            level: 2,
            class: "mb-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.performerDetails.performerProfile?.nickname), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PerformerRating, { performer: _ctx.performerDetails }, null, 8, ["performer"])
        ]),
        _createVNode(_component_PerformerBadgeList, {
          "performer-profile": _ctx.performerDetails.performerProfile,
          class: "performer-summary__badges",
          "force-unlocked-state": ""
        }, null, 8, ["performer-profile"]),
        (_ctx.performerStats.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.performerStats, (stat) => {
                return (_openBlock(), _createBlock(_component_IconLabel, {
                  key: stat.value,
                  text: `${stat.label} ${stat.value}`
                }, _createSlots({ _: 2 }, [
                  (stat.iconName)
                    ? {
                        name: "icon",
                        fn: _withCtx(() => [
                          _createVNode(_component_AppIcon, {
                            name: stat.iconName
                          }, null, 8, ["name"])
                        ])
                      }
                    : undefined
                ]), 1032, ["text"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_PerformerIndustries, { performer: _ctx.performerDetails }, null, 8, ["performer"])
      ])
    ]),
    _: 1
  }))
}