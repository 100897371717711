
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { PerformerDetails_performerDetails_PerformerDetails } from "@/api/performer/__generated__/PerformerDetails";
import Card from "@/shared/components/Card.vue";

export default defineComponent({
  props: {
    performerDetails:
      Object as PropType<PerformerDetails_performerDetails_PerformerDetails>,
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    Card,
  },
});
