import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_AvatarIcon = _resolveComponent("AvatarIcon")!
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "app-avatar__container relative",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickable ? _ctx.$emit('on-avatar-click') : null))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["app-avatar", [
        `app-avatar--${_ctx.theme}`,
        _ctx.clickable && 'cursor-pointer',
        _ctx.noBorder && 'border-none',
      ]])
    }, [
      (_ctx.avatarSrc !== undefined)
        ? (_openBlock(), _createBlock(_component_a_image, {
            key: 0,
            preview: false,
            src: _ctx.avatarSrc ?? '#',
            fallback: _ctx.fallbackSrc,
            class: "app-avatar__img absolute inset-0",
            alt: _ctx.altText ?? ''
          }, null, 8, ["src", "fallback", "alt"]))
        : (_openBlock(), _createBlock(_component_AvatarIcon, { key: 1 }))
    ], 2),
    _renderSlot(_ctx.$slots, "extra"),
    (_ctx.showDeleteButton)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "app-avatar__delete-button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('on-delete')), ["stop"]))
        }, [
          _createVNode(_component_AppIcon, { name: "trashWhite" })
        ]))
      : _createCommentVNode("", true)
  ]))
}