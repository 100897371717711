
import { defineComponent, ref, watchEffect, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useShortenedLink } from "@/shared/composables/useShortenedLink";
import { PerformerDetails_performerDetails_PerformerDetails } from "@/api/performer/__generated__/PerformerDetails";
import { startCase, camelCase } from "lodash";
import Card from "@/shared/components/Card.vue";
import InputTextCopy from "@/shared/components/InputTextCopy.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";

/**
 * Used in shortened link.
 *
 * Example:
 * Nickname = "Juan D."
 * Preferred Short URL = "/creatorJuanD"
 */
const PREFERRED_SHORT_URL_PREFIX = "creator";

export default defineComponent({
  props: {
    performerDetails:
      Object as PropType<PerformerDetails_performerDetails_PerformerDetails>,
  },
  setup(props) {
    const { t } = useI18n();
    const shortLink = ref("");
    const { generateShortenedLink, generateShortenedLinkLoading } =
      useShortenedLink();

    watchEffect(async () => {
      if (!shortLink.value && props.performerDetails) {
        const targetUrl = window.location.origin + window.location.pathname;

        let generatedShortUrl: string | undefined = "";
        if (props.performerDetails.performerProfile?.nickname) {
          const formattedNickname = startCase(
            props.performerDetails.performerProfile.nickname
          );
          const performerSlug = camelCase(
            PREFERRED_SHORT_URL_PREFIX + formattedNickname
          );

          generatedShortUrl = await generateShortenedLink({
            url: targetUrl,
            preferredShortUrl: window.location.origin + `/${performerSlug}`,
          });
        } else {
          generatedShortUrl = await generateShortenedLink({
            url: targetUrl,
          });
        }

        shortLink.value = generatedShortUrl ?? "";
      }
    });

    return {
      t,
      shortLink,
      generateShortenedLinkLoading,
    };
  },
  components: {
    Card,
    InputTextCopy,
    AppIcon,
  },
});
