import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, _mergeProps({
    type: "primary",
    class: "performer-hire-button gradient-pink"
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("Hire me as a UGC creator")), 1)
    ]),
    _: 1
  }, 16))
}