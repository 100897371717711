
import { defineComponent, PropType, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { PerformerDetails_performerDetails_PerformerDetails } from "@/api/performer/__generated__/PerformerDetails";
import { isValidInstagramUrl } from "@/shared/components/VideoEmbed/InstagramEmbedded.vue";
import { isValidTiktokUrl } from "@/shared/components/VideoEmbed/TikTokEmbedded.vue";
import { isValidVimeoUrl } from "@/shared/components/VimeoEmbedded.vue";
import { createTikTokEmbeddedScript } from "@/shared/utils/tiktok";
import { createVimeoEmbeddedScript } from "@/shared/utils/vimeo";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import Card from "@/shared/components/Card.vue";
import VideoEmbedCarousel from "@/shared/components/VideoEmbed/VideoEmbedCarousel.vue";
import InstagramEmbedded from "@/shared/components/VideoEmbed/InstagramEmbedded.vue";
import VimeoEmbedded from "@/shared/components/VimeoEmbedded.vue";
import TikTokEmbedded from "@/shared/components/VideoEmbed/TikTokEmbedded.vue";

export default defineComponent({
  props: {
    performerDetails:
      Object as PropType<PerformerDetails_performerDetails_PerformerDetails>,
  },
  setup(props) {
    const { t } = useI18n();
    const { isMobile } = useWindowSizeChecker();
    const performerVideosRef = ref();

    const embedUrls = computed(
      () => props.performerDetails?.performerProfile?.videoUrls ?? []
    );

    const handleInjectEmbedScripts = () => {
      try {
        // prepend to the component only, so it will be destroyed on unmount
        performerVideosRef.value.prepend(createTikTokEmbeddedScript());
        performerVideosRef.value.prepend(createVimeoEmbeddedScript());
      } catch (error) {
        console.log("Something went wrong when trying to inject embed scripts");
      }
    };

    return {
      t,
      isMobile,
      performerVideosRef,
      embedUrls,
      isValidInstagramUrl,
      isValidVimeoUrl,
      isValidTiktokUrl,
      handleInjectEmbedScripts,
    };
  },
  components: {
    Card,
    VideoEmbedCarousel,
    InstagramEmbedded,
    VimeoEmbedded,
    TikTokEmbedded,
  },
});
