
import { defineComponent, PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
import { PerformerDetails_performerDetails_PerformerDetails } from "@/api/performer/__generated__/PerformerDetails";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import Card from "@/shared/components/Card.vue";

export default defineComponent({
  props: {
    performerDetails:
      Object as PropType<PerformerDetails_performerDetails_PerformerDetails>,
  },
  setup(props) {
    const { t } = useI18n();
    const { isMobile } = useWindowSizeChecker();
    const images = computed(
      () => props.performerDetails?.performerProfile?.images ?? []
    );
    const imagesCarouselSlideToShow = computed(() =>
      images.value.length > 3 ? 3 : 1
    );
    return {
      t,
      isMobile,
      images,
      imagesCarouselSlideToShow,
    };
  },
  components: {
    Card,
  },
});
