
import { defineComponent, PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
import { PerformerDetails_performerDetails_PerformerDetails } from "@/api/performer/__generated__/PerformerDetails";
import { PerformerStat } from "@/shared/utils/performerProfileHelper";
import { getIndustryOptionData } from "@/shared/utils/kycOptions";
import Card from "@/shared/components/Card.vue";
import Avatar from "@/shared/components/Avatar.vue";
import PerformerBadgeList from "@/shared/components/Performers/PerformerBadgeList.vue";
import IconLabel from "@/shared/components/IconLabel.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import PerformerRating from "@/shared/components/Performers/PerformerRating.vue";
import PerformerIndustries from "@/shared/components/Performers/PerformerIndustries.vue";

export default defineComponent({
  props: {
    performerDetails:
      Object as PropType<PerformerDetails_performerDetails_PerformerDetails>,
  },
  setup(props) {
    const { t } = useI18n();

    const performerStats = computed(
      () =>
        [
          {
            label: t("Videos Made:"),
            value: props.performerDetails?.performerProfile?.videoCount,
            iconName: "playButton",
          },
          {
            label: t("Average Delivery Days:"),
            value: props.performerDetails?.performerProfile?.deliveryTime,
            iconName: "clock",
          },
          {
            label: t("Location:"),
            value: props.performerDetails?.performerProfile?.location,
            iconName: "location",
          },
        ].filter((stat) => !!stat.value) as PerformerStat[]
    );

    return {
      performerStats,
      getIndustryOptionData,
    };
  },
  components: {
    Card,
    Avatar,
    PerformerBadgeList,
    IconLabel,
    AppIcon,
    PerformerRating,
    PerformerIndustries,
  },
});
