
import { defineComponent } from "vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import { useI18n } from "vue-i18n";
import { copyText } from "@/shared/utils/browser";
import message from "@/shared/utils/message";

export default defineComponent({
  props: {
    text: String,
    successMessage: String,
  },
  setup(props) {
    const { t } = useI18n();

    const handleCopy = () => {
      const success = copyText(props.text ?? "");

      if (success) {
        const successMessageToDisplay =
          props.successMessage ?? t("Successfully copied!");
        message["success"](successMessageToDisplay);
      } else {
        message["warning"](
          t("Failed to copy to clipboard. Please manually select the text.")
        );
      }
    };

    return { t, handleCopy };
  },
  methods: { copyText },
  components: { InputText },
});
